<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Parking_card_name')">
                <el-input
                  v-model.trim="formInline.name"
                  maxlength="10"
                  placeholder="请输入停车卡名称"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model="formInline.operatorId" filterable>
                  <el-option label="全部" value="" />
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Is_it_for_online_sales')">
                <el-select v-model="formInline.appSale">
                  <el-option label="全部" value="" />
                  <el-option label="是" :value="1" />
                  <el-option label="否" :value="0" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model="formInline.state">
                  <el-option label="全部" value="" />
                  <el-option label="启用" :value="2" />
                  <el-option label="停用" :value="4" />
                </el-select>
              </el-form-item>
            </div>
            <!-- <el-form-item label="销售渠道">
            <el-select v-model="formInline.appSale">
              <el-option label="全部渠道" value="" />
              <el-option label="线上销售" :value="1" />
              <el-option label="后台录入" :value="0" />
            </el-select>
          </el-form-item> -->
            <!-- <el-form-item label="销售状态">
            <el-select v-model="formInline.states">
              <el-option label="全部状态" value="" />
              <el-option label="未上架" :value="0" />
              <el-option label="未开售" :value="1" />
              <el-option label="出售中" :value="2" />
              <el-option label="已售完" :value="3" />
              <el-option label="已下架" :value="4" />
            </el-select>
          </el-form-item> -->
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.button.add"
                @click="$router.push('/parkRuleAddEdit')"
                :loading="loading"
                >添加停车卡
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="50" align="center"></el-table-column>
          <el-table-column
            :show-overflow-tooltip="item.prop == 'scopeDesc'"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
            <template slot-scope="scope">
              <!-- @click="stateChanged(scope.row)" -->
              <!-- :beforeChange="stateChanged(scope.row)" -->
              <el-switch
                v-model="scope.row.state"
                v-if="item.prop == 'state'"
                @change="stateChanged2(scope.row)"
                :active-value="Number(2)"
                :inactive-value="Number(1)"
              />
              <el-switch
                v-model="scope.row.appSale"
                @change="stateChanged1(scope.row)"
                v-else-if="item.prop == 'appSale'"
              />
              <div v-else-if="item.prop == 'quantities'">
                {{
                  scope.row[scope.column.property] == 0 ? "不限" : scope.row[scope.column.property]
                }}
              </div>
              <div v-else-if="item.prop == 'actualAmount'">
                {{
                  scope.row[scope.column.property]
                    ? (scope.row[scope.column.property] / 100).toFixed(2)
                    : 0.0
                }}
              </div>
              <div v-else>
                {{ scope.row[scope.column.property] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            v-if="$route.meta.authority.button.edit || $route.meta.authority.button.delete"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="c">查看</el-dropdown-item>
                  <!-- <el-dropdown-item
                    command="a"
                    v-if="
                      $route.meta.authority.button.edit && scope.row.state === 0
                    "
                    >修改</el-dropdown-item
                  > -->
                  <el-dropdown-item command="a" v-if="scope.row.state == 1">修改</el-dropdown-item>
                  <el-dropdown-item command="f">日志</el-dropdown-item>
                  <!-- <el-dropdown-item command="d" v-if="scope.row.state === 0"
                    >上架</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="
                      $route.meta.authority.button.delete &&
                      (scope.row.state === 2 || scope.row.state === 3)
                    "
                    >下架</el-dropdown-item
                  >
                  <el-dropdown-item command="e" v-if="scope.row.state === 4"
                    >重新上架</el-dropdown-item
                  > -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import autoComplete from '@/components/autocomplete';
import { dateFormat } from "@/common/js/public";

export default {
  name: "parkingCardRule",
  data() {
    return {
      tenantList: [],
      temParkData: {},
      modelvalue: "",
      streetList: [],
      street: "",
      areaId: "",
      streetId: "",
      area: "",
      state1: "",
      parkType: [],
      parkScope: [],
      commercialStatus: [],
      parkData: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "name",
          label: "停车卡名称",
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "berthMaxNum",
          label: "泊位数",
          width: "",
        },
        {
          prop: "carMaxNum",
          label: "可绑车辆数",
          width: "",
        },
        {
          prop: "quantities",
          label: "停车卡数量",
          formatter: (r) => {
            return r.quantities == 0 ? "不限" : r.quantities;
          },
        },
        // {
        //   prop: "originalPrice",
        //   label: "商品原价",
        //   width: "",
        //   formatter: (r, c, v) => {
        //     return v ? (v / 100).toFixed(2) : 0.0;
        //   },
        // },
        // {
        //   prop: "discountAmount",
        //   label: this.$t("list.preferential_amount"),
        //   width: "",
        //   formatter: (r, c, v) => {
        //     return v ? (v / 100).toFixed(2) : 0.0;
        //   },
        // },
        {
          prop: "actualAmount",
          label: "销售价格",
          width: "",
          formatter: (r, c, v) => {
            return v ? (v / 100).toFixed(2) : 0.0;
          },
        },
        // {
        //   prop: "appSaleDesc",
        //   label: "销售渠道",
        //   width: "",
        // },
        {
          prop: "state",
          label: "启用",
          width: "",
        },
        {
          prop: "appSale",
          label: "线上销售",
          width: "",
        },
        {
          prop: "creatorName",
          label: "最后修改人",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        scope: "0",
        parkId: "",
        states: "",
        state: "",
        appSale: "",
      },
      scopeList: [],
      statusList: [],
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    // 启用按钮
    stateChanged2(row) {
      console.log(row.state, "row.state------12");
      if (row.state == 1) {
        this.$confirm("停用后停车卡规则失效，确定要停用吗？", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios
              .post("/acb/2.0/parkCardRule/saleOffOrOn", {
                data: {
                  parkCardRuleId: row.parkCardRuleId,
                  // 下架 4 上架 2
                  // state : 1 上架 2 下架
                  status: row.state == 1 ? 4 : 2,
                },
              })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.searchData();
              });

            return true;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            this.searchData();
            return false;
          });
      } else if (row.state == 2) {
        this.$axios
          .post("/acb/2.0/parkCardRule/saleOffOrOn", {
            data: {
              parkCardRuleId: row.parkCardRuleId,
              // 下架 4 上架 2
              // state : 1 上架 2 下架
              status: row.state == 1 ? 4 : 2,
            },
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.searchData();
          });
      }
    },
    getRuleState() {
      this.$axios.get("/acb/2.0/parkCardRule/getParkCardRuleState").then((res) => {
        this.statusList = res.value.parkCardRuleState;
      });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 修改列表状态按钮
    stateChanged(e) {
      this.$confirm("停用后停车卡规则失效，确定要停用吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.saleOff(e.parkCardRuleId, 4)
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          return true;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          return false;
        });
      // console.log(e, "stateChanged");
    },
    // 修改列表销售按钮
    stateChanged1(e) {
      console.log(e.appSale, "e.appSale");
      console.log(e, "e.appSale");
      let url = "/acb/2.0/parkCardRule/appSaleOffOrOn";
      this.$axios
        .post(url, {
          data: {
            parkCardRuleId: e.parkCardRuleId,
            appSale: e.appSale ? 1 : 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.searchData();
          } else {
            this.$message({
              type: "info",
              message: res.desc,
            });
          }

          // this.statusList = res.value.status
        });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getDownMenu() {
      this.$axios.get("/acb/2.0/parkCard/getSelectionValues").then((res) => {
        this.scopeList = res.value.scope;
        // this.statusList = res.value.status
      });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({
          path: "/parkRuleAddEdit",
          query: data,
        });
      }
      if (cmd == "b") {
        this.$confirm("下架的停车卡将不可购买，已购买的会继续生效，确认下架？", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        }).then((res) => {
          this.saleOff(data.parkCardRuleId, 4);
        });
      }
      if (cmd == "c") {
        this.$router.push({
          path: "/parkRuleDetail",
          query: { parkCardRuleId: data.parkCardRuleId },
        });
      }
      if (cmd == "d") {
        this.$confirm("确认上架该停车卡？", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        }).then((res) => {
          this.saleOff(data.parkCardRuleId, 2);
        });
      }
      if (cmd == "e") {
        this.$router.push({
          path: "/parkRuleAddEdit",
          query: {
            ...data,
            cardType: "again",
          },
        });
      }
      if (cmd == "f") {
        this.$router.push({
          path: "/parkingCardManagementLog",
          query: data,
        });
      }
    },
    // 上、下架操作
    saleOff(parkCardRuleId, status) {
      this.$axios
        .post("/acb/2.0/parkCardRule/saleOffOrOn", {
          data: {
            parkCardRuleId,
            status,
          },
        })
        .then((res) => {
          this.$message("操作成功！");
          this.searchData();
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      let url = "/acb/2.0/parkCardRule/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, "提示");
          }
        })
        .catch((_) => {
          this.total = 0;
          this.loading = false;
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  activated() {
    this.searchData();
  },
  components: {
    // autoComplete
  },
  created() {
    this.getTenantList();
    this.getRuleState();
    this.getDownMenu();
  },
  computed: {},
  mounted() {
    // console.log(this.$route);
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
    margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
